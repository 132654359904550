import { render, staticRenderFns } from "./record_audio_modal.vue?vue&type=template&id=55d2289a&lang=pug&"
import script from "./record_audio_modal.vue?vue&type=script&lang=js&"
export * from "./record_audio_modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports