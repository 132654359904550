import { render, staticRenderFns } from "./choose_template.vue?vue&type=template&id=8b7a4326&lang=pug&"
import script from "./choose_template.vue?vue&type=script&lang=coffee&"
export * from "./choose_template.vue?vue&type=script&lang=coffee&"
import style0 from "./choose_template.vue?vue&type=style&index=0&id=8b7a4326&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports