import { render, staticRenderFns } from "./notifications.vue?vue&type=template&id=ea4f2f98&lang=pug&"
import script from "./notifications.vue?vue&type=script&lang=coffee&"
export * from "./notifications.vue?vue&type=script&lang=coffee&"
import style0 from "./notifications.vue?vue&type=style&index=0&id=ea4f2f98&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports