import { render, staticRenderFns } from "./browse_page.vue?vue&type=template&id=2ee05ca3&lang=pug&"
import script from "./browse_page.vue?vue&type=script&lang=coffee&"
export * from "./browse_page.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports